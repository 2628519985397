import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { List } from 'wui/List';
import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { Button } from 'wui/Button';
import { Typography } from 'wui/Typography';
import { Divider } from 'wui/Divider';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';

import { FeedItemSearchResult } from 'common/components/FeedItemSearchResult';
import { useController } from 'common/context/controller';
import {
  selectFeedResult,
  selectFeedSearchItems,
  selectFeedSearchMetadata,
} from 'store/selectors';

interface IProps {
  query: string;
  groupId: string;
  container: HTMLElement | null;

  onReset(): void;
}

export function FeedResultsList(props: IProps) {
  const { groupId, query, container, onReset } = props;

  const { t } = useTranslation();
  const { feed$ } = useController();

  const result = useSelector(selectFeedResult);
  const items = useSelector(selectFeedSearchItems);
  const meta = useSelector(selectFeedSearchMetadata);

  const hasMore = result.total! > items.length;

  if (!items.length) {
    return (
      <EmptyState
        bw
        variant="section"
        title={t('groups-web.group-search.feed-empty-state.title')}
        subtitle={t('groups-web.group-search.feed-empty-state.subtitle')}
        action={
          <Button outlined variant="basic" onClick={onReset}>
            {t('groups-web.group-search.feed-empty-state.reset-button')}
          </Button>
        }
      />
    );
  }

  return (
    <Stack gap="SP2" direction="vertical">
      <Box padding="SP0 SP6">
        <Typography secondary>
          {t('groups-web.group-search.feed.results-total', {
            count: result.total,
          })}
        </Typography>
      </Box>

      <InfiniteScroll
        threshold={400}
        useWindow={false}
        hasMore={hasMore}
        initialLoad={false}
        loadMore={handleLoadMore}
        getScrollParent={() => container}
        loader={
          <Show if={meta.fetchMore.pending}>
            <Box align="center">
              <Spinner />
            </Box>
          </Show>
        }
      >
        <List disablePadding separator={<Divider bw inset />}>
          {items.map((item) => (
            <FeedItemSearchResult
              bw
              item={item}
              key={item.feedItemId}
              topBottomBorders={false}
              sideBorders={false}
            />
          ))}
        </List>
      </InfiniteScroll>
    </Stack>
  );

  function handleLoadMore() {
    if (!meta.fetchMore.pending) {
      feed$.search({
        query,
        groupId,
        paging: { offset: items.length },
      });
    }
  }
}

FeedResultsList.displayName = 'FeedResultsList';
