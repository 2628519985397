import React from 'react';
import { isEqual } from 'lodash';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { truncateRichContent } from 'ricos-content/libs/truncate-rich-content';
import { WixRicosViewer, WixRicosViewerProps } from '@wix/ricos-viewer';
import { extractUoUParams } from '@wix/ricos-common/libs/extractUoUParams';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { selectUserToken } from 'store/selectors';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { TextButton } from 'wui/TextButton';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import { useViewerPlugins } from './hooks/useViewerPlugins';

import { viewerTheme, viewerWiredTheme } from './viewerTheme';
import classes from './ricos.scss';

export interface RichContentViewerProps
  extends Omit<WixRicosViewerProps, 'biSettings' | 'instance'>,
    TPAComponentProps {
  groupId?: string;
  truncate?: boolean;
  wired?: boolean;
  usage: 'About' | 'FeedItem' | 'CustomTab';
  postId: string;
  containerId: string;
  contentId?: 'custom_tab' | 'about_group' | string;
}

export const RichContentViewer: React.FC<RichContentViewerProps> = (props) => {
  const {
    groupId,
    usage,
    className,
    postId,
    contentId,
    containerId,
    wired,
    ...rest
  } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { language, isMobile } = useEnvironment();
  const plugins = useViewerPlugins(groupId as string);
  const instance = useSelector(selectUserToken);

  const [isTruncated, setIsTruncated] = React.useState(props.truncate);

  const truncated = React.useMemo(() => {
    if (!props.content) {
      return {
        content: props.content,
        isTruncated: false,
      };
    }

    const content = truncateRichContent(props.content, {
      wordCount: 150,
      maxPlugins: 2,
      nodeCount: 6,
    });

    return {
      content,
      isTruncated: !isEqual(content, props.content),
    };
  }, [props.content]);

  const content = isTruncated ? truncated.content : props.content;

  return (
    <div
      data-hook={props['data-hook']}
      className={cls(classes.viewer, className, {
        [classes.mobile]: isMobile,
        [classes.wired]: wired,
      })}
    >
      <WixRicosViewer
        {...rest}
        theme={wired ? viewerWiredTheme : viewerTheme}
        content={content}
        instance={instance}
        plugins={plugins}
        locale={language}
        wixExperiments={experiments}
        linkSettings={{
          anchorTarget: '_blank',
          rel: { nofollow: true, ugc: true },
        }}
        biSettings={{
          usage,
          postId,
          contentId,
          containerId,
          consumer: 'Groups',
          platform: 'Livesite',
          defaultParams: extractUoUParams({ biLogger: bi }),
        }}
      />

      <Show if={props.truncate && truncated.isTruncated}>
        <Box marginTop="SP2">
          <TextButton wired variant="link" onClick={toggleTruncateClick}>
            {isTruncated
              ? t('groups-web.discussion.feed.see-more')
              : t('groups-web.discussion.feed.see-less')}
          </TextButton>
        </Box>
      </Show>
    </div>
  );

  function toggleTruncateClick() {
    setIsTruncated((isTruncated) => !isTruncated);
  }
};

RichContentViewer.displayName = 'RichContentViewer';
RichContentViewer.defaultProps = {
  wired: true,
};
