import React from 'react';

import { useRicosFormat } from 'common/utils/ricos';
import { RichContentViewer } from 'common/components/RichContent/Viewer';

import type { IFeedItem } from 'api/feed/types';

import { CardContent } from 'wui/CardContent';

import { GROUPS_APP_DEFINITION_ID } from '../../../constants';
import { ActivityContent } from './ActivityContent';
import { getAriaId } from './a11y';

interface IProps {
  item: IFeedItem;
  truncate?: boolean;
}

export function FeedItemContent(props: IProps) {
  const { item, truncate } = props;

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;

  const content = useRicosFormat(item.entity?.body?.content);

  if (item.activity) {
    return <ActivityContent item={item} />;
  }

  if (!content) {
    return null;
  }

  return (
    <CardContent id={getAriaId(feedItemId, 'content')} as="div">
      <RichContentViewer
        usage="FeedItem"
        postId={feedItemId}
        containerId={GROUPS_APP_DEFINITION_ID}
        groupId={groupId}
        truncate={truncate}
        content={content}
        contentId={item.feedItemId}
      />
    </CardContent>
  );
}

FeedItemContent.displayName = 'FeedItemContent';
