import { useMemo } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import {
  createStylesParam,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

import type { IFeedItem } from 'api/feed/types';

export function useHighLightColor() {
  const styles = useStyles();

  return useMemo(
    () =>
      styles.get(
        createStylesParam('feed-item-highlight-color', {
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam(`color-8`, 0.3),
        }),
      ),
    [],
  );
}

export function useItemHighlight(item: IFeedItem) {
  const preview = useMemo(() => {
    const [html] =
      item.highlight?.match(
        /(?:\w+\W+){0,20}(\w+)?<em>.*?<\/em>(?:.*?\W+\w+){0,20}/g,
      ) ?? [];

    const truncatedAfter = !item.highlight?.endsWith(html!);
    const truncatedBefore = !item.highlight?.startsWith(html!);

    return [truncatedBefore && '...', html, truncatedAfter && '...']
      .filter(Boolean)
      .join('');
  }, [item.highlight]);

  const total = useMemo(() => {
    return item.highlight?.match(/<em>/g)?.length ?? 0;
  }, [item.highlight]);

  const count = useMemo(() => {
    return preview?.match(/<em>/g)?.length ?? 0;
  }, [preview]);

  return { preview, total, count };
}
