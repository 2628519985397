import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';
import { selectGroupSlugById } from 'store/selectors';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Typography } from 'wui/Typography';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Card, type ICardProps } from 'wui/Card';
import { ChevronRight as ChevronRightIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Link } from '../Link';
import { FeedItemHeader } from '../FeedItem/FeedItemHeader';

import { useHighLightColor, useItemHighlight } from './helpers';
import classes from './styles.scss';

interface IProps {
  item: IFeedItem;
}

export function FeedItemSearchResult(props: IProps & Partial<ICardProps>) {
  const { item, className, ...rest } = props;

  const { t } = useTranslation();
  const color = useHighLightColor();
  const { isMobile } = useEnvironment();
  const highlight = useItemHighlight(item);

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const hasMoreResults = highlight.total > highlight.count;

  const slug = useSelector(selectGroupSlugById(groupId));

  return (
    <Card
      {...rest}
      className={cls(classes.root, className)}
      style={{ '--highlight-color': color.value } as React.CSSProperties}
    >
      <FeedItemHeader
        item={item}
        padding="0 SP6"
        action={
          <Show if={!isMobile && hasMoreResults}>
            <Typography secondary variant="p2-14">
              {t('groups-web.search.feed-item.more-results')}
            </Typography>
          </Show>
        }
      />

      <CardContent as="div" padding="0 SP6">
        <Typography
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: highlight.preview }}
        />
      </CardContent>

      <CardActions padding="0 SP6">
        <Box
          gap="SP3"
          width="100%"
          align="space-between"
          direction={isMobile && hasMoreResults ? 'vertical' : 'horizontal'}
        >
          <Box gap="SP3">
            <Typography>
              {t('groups-web.discussion.feed.reactions.total_icu', {
                count: item.reactions?.total ?? 0,
              })}
            </Typography>
            <Typography>
              {t('groups-web.discussion.feed.total-comments_icu', {
                count: item.latestComments?.total ?? 0,
              })}
            </Typography>
          </Box>

          <Box align="space-between" verticalAlign="middle">
            <Show if={isMobile && hasMoreResults}>
              <Typography secondary variant="p2-14">
                {t('groups-web.search.feed-item.more-results')}
              </Typography>
            </Show>

            <Link
              variant="link"
              state="group.discussion.post"
              params={{ slug, feedItemId }}
              suffixIcon={<ChevronRightIcon />}
            >
              {t('groups-web.search.feed-item.view-post')}
            </Link>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
}

FeedItemSearchResult.displayName = 'FeedItemSearchResult';
