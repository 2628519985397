import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from '../Box';
import { Show } from '../Show';
import { Typography } from '../Typography';

import classes from './CardHeader.scss';

export interface ICardHeaderProps
  extends Omit<React.ComponentProps<typeof Box>, 'title'> {
  wired?: boolean;
  avatar?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactNode;

  disableTypography?: boolean;
  titleProps?: React.ComponentProps<typeof Typography>;
  subtitleProps?: React.ComponentProps<typeof Typography>;
}

export function CardHeader(props: ICardHeaderProps) {
  const {
    wired,
    avatar,
    action,
    disableTypography,
    titleProps,
    subtitleProps,
    className,
    title: _,
    subtitle: __,
    ...rest
  } = props;

  const { isMobile } = useEnvironment();

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  if (
    !disableTypography &&
    props.title &&
    (props.title as React.ReactElement).type !== Typography
  ) {
    title = (
      <Typography
        variant={isMobile ? 'h2-14' : 'h2-16'}
        as={typeof props.title === 'string' ? 'h2' : 'div'}
        {...titleProps}
        className={cls(classes.title, titleProps?.className)}
      >
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (
    !disableTypography &&
    props.subtitle &&
    (props.subtitle as React.ReactElement).type !== Typography
  ) {
    subtitle = (
      <Typography
        secondary
        variant={isMobile ? 'p2-12' : 'p2-14'}
        as={typeof props.subtitle === 'string' ? 'p' : 'div'}
        {...subtitleProps}
        className={cls(classes.subtitle, subtitleProps?.className)}
      >
        {props.subtitle}
      </Typography>
    );
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }

  return (
    <Box
      gap="SP3"
      padding="0 SP5"
      verticalAlign="top"
      className={cls(classes.root, className, { [classes.wired]: wired })}
      {...rest}
    >
      <Show if={!!avatar}>
        <div className={classes.avatar}>{avatar}</div>
      </Show>
      <Box direction="vertical" className={classes.text}>
        {title}
        {subtitle}
      </Box>
      <Show if={!!action}>
        <div className={classes.action}>{action}</div>
      </Show>
    </Box>
  );
}

CardHeader.displayName = 'wui/CardHeader';
