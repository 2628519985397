import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { selectSearchMetadata } from 'store/selectors';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Spinner } from 'wui/Spinner';
import { ErrorState } from 'wui/ErrorState';
import { EmptyState } from 'wui/EmptyState';

import { FeedResultsList } from './FeedResultsList';

interface IProps {
  groupId: string;
  query?: string;
  container: HTMLElement | null;

  onReset(): void;
}

export function SearchResults(props: IProps) {
  const { groupId, query, container, onReset } = props;

  const { t } = useTranslation();
  const { search$ } = useController();

  const { isLoading, isError } = useSelector(selectSearchMetadata);

  useEffect(() => {
    if (query) {
      search$.query({
        query,
        groupId,
      });
    }
  }, [groupId, query]);

  if (!query) {
    return (
      <EmptyState
        bw
        variant="section"
        title={t('groups-web.group-search.empty-state.title')}
        subtitle={t('groups-web.group-search.empty-state.subtitle')}
      />
    );
  }

  if (isLoading) {
    return (
      <Box
        height="100%"
        align="center"
        direction="vertical"
        verticalAlign="middle"
      >
        <Spinner />
      </Box>
    );
  }

  if (isError) {
    return (
      <ErrorState
        bw
        variant="section"
        onRetry={handleRetry}
        buttonProps={{ variant: 'basic', outlined: true }}
        title={t('groups-web.group-search.error-state.title')}
        subtitle={t('groups-web.group-search.error-state.subtitle')}
        actionLabel={t('groups-web.group-search.error-state.retry')}
      />
    );
  }

  return (
    <FeedResultsList
      query={query}
      groupId={groupId}
      onReset={onReset}
      container={container}
    />
  );

  function handleRetry() {
    search$.query({
      groupId,
      query: query!,
    });
  }
}

SearchResults.displayName = 'SearchResults';
