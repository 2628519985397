import { useMemo } from 'react';

import type {
  GalleryData,
  GIFData,
  ImageData,
  Media,
  RichContent,
  VideoData,
} from 'ricos-schema';
import type { DraftContent } from 'ricos-content';
import {
  ensureRicosContent,
  fromPlainText,
} from 'ricos-content/libs/converters';
import { getPluginData, getText } from 'ricos-content/libs/content-application';
import type { Node_Type } from 'wix-rich-content-common';
import { getAbsoluteUrl } from 'wix-rich-content-plugin-gallery/libs/baseUrlConverter';

export interface IItemContent {
  text?: string;
  gifs: GIFData[];
  images: ImageData[];
  videos: VideoData[];
  galleries: GalleryData[];
}

export function extractContent(
  content: RichContent | null | undefined,
): IItemContent {
  if (!content) {
    return {
      text: undefined,
      gifs: [],
      images: [],
      videos: [],
      galleries: [],
    };
  }

  return {
    text: getText(content).join(' '),
    images: prepareImages(content),
    videos: prepareVideos(content),
    galleries: prepareGalleries(content),
    gifs: getPluginData(content, 'GIF' as Node_Type),
  };
}

function prepareImages(content: RichContent): ImageData[] {
  const images: ImageData[] = getPluginData(content, 'IMAGE' as Node_Type);

  return images.map((image) => ({
    ...image,
    image: prepareMediaItem(image.image),
  }));
}

function prepareGalleries(content: RichContent): GalleryData[] {
  const galleries: GalleryData[] = getPluginData(
    content,
    'GALLERY' as Node_Type,
  );

  return galleries.map((gallery) => ({
    ...gallery,
    items: gallery.items.map((item) => ({
      ...item,
      image: !item.image
        ? undefined
        : {
            ...item.image,
            media: prepareMediaItem(item.image.media),
          },
      video: !item.video
        ? undefined
        : {
            ...item.video,
            media: prepareMediaItem(item.video.media),
            thumbnail: prepareMediaItem(item.video.thumbnail),
          },
    })),
  }));
}

function prepareVideos(content: RichContent): VideoData[] {
  const videos: VideoData[] = getPluginData(content, 'VIDEO' as Node_Type);

  return videos.map((video) => ({
    ...video,
    thumbnail: prepareMediaItem(video.thumbnail),
    video: prepareMediaItem(video.video),
  }));
}

function prepareMediaItem(media?: Media): Media | undefined {
  if (!media) {
    return undefined;
  }

  const id = media.src?.id || media.src?.url;
  const isVideo = media.duration !== undefined;

  if (!id) {
    return media;
  }

  return {
    ...media,
    src: {
      ...media.src,
      url: getAbsoluteUrl(id, isVideo ? 'video' : 'image'),
    },
  };
}

/**
 * safeJsonParse from 'wix-rich-content-common' is deprecated to use, so we have our own instead
 * https://github.com/wix-private/ricos/pull/6749#issuecomment-2158124763
 * @param jsonString
 */
export function safeJsonParse<T extends RichContent | DraftContent>(
  jsonString?: string | null | undefined,
): T | null | undefined {
  if (jsonString === undefined || jsonString === null) {
    return jsonString;
  }

  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return undefined;
  }
}

export function useRicosFormat(data: string | null | undefined) {
  return useMemo(() => {
    const content = safeJsonParse<DraftContent>(data);

    if (!content) {
      return fromPlainText(data ?? '');
    }

    try {
      return ensureRicosContent(content);
    } catch (e) {
      return fromPlainText(data ?? '');
    }
  }, [data]);
}
